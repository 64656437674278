@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url('~material-design-icons/iconfont/MaterialIcons-Regular.eot'); /* For IE6-8 */
    src: local('Material Icons'), local('MaterialIcons-Regular'),
        url('~material-design-icons/iconfont/MaterialIcons-Regular.woff2') format('woff2'),
        url('~material-design-icons/iconfont/MaterialIcons-Regular.woff') format('woff'),
        url('~material-design-icons/iconfont/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-display: block;
    font-weight: 300;
    src: local('Lato Light'), local('Lato-Light'),
        url('~@fontsource/lato/files/lato-latin-300-normal.woff2') format('woff2');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-display: block;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'),
        url('~@fontsource/lato/files/lato-latin-400-normal.woff2') format('woff2');
}

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-display: block;
    font-weight: 700;
    src: local('Lato SemiBold'), local('Lato-SemiBold'),
        url('~@fontsource/lato/files/lato-latin-700-normal.woff2') format('woff2');
}

html {
    height: 100%;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    user-select: none;
    background-color: #2e383f;
}

#root {
    height: 100%;
    display: flex;
    flex: 1;
}

textarea,
input {
    outline: none;
    border: none;
    background: none;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    outline: none;
}

.material-icons {
    font-display: block;
}

.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-14 {
    font-size: 14px;
}

.material-icons.md-12 {
    font-size: 12px;
}

button {
    appearance: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    margin: 0;
    text-align: center;
    background: none;
}

/* fixes sort when moving columns in ag-grid */
.ag-dnd-ghost {
    position: absolute;
    z-index: 1;
}
